<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("method.See {type} adapter config", {
                  type: "密码"
                })}：${name}`
              : this.type === "create"
              ? $t("method.Create {type} adapter config", { type: "密码" })
              : `${$t("method.Edit {type} adapter config", {
                  type: "密码"
                })}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        密码配置列表项
      </v-alert>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader>认证方式ID</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            v-model="external_id"
            :disabled="type !== 'create'"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="$t('ID Info')"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 显示名称</v-subheader>
        </v-col>

        <v-col>
          <v-textarea
            :auto-grow="true"
            rows="1"
            v-model="name"
            :disabled="is_readonly"
            :readonly="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="checkSources">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 合并密码源</v-subheader>
        </v-col>
        <v-col cols="9">
          <draggable :list="checkSources" handle=".mover">
            <transition-group>
              <div v-for="item in checkSources" :key="item.id">
                <v-list-item style="border-bottom:1px solid #e6e6e6">
                  <template v-slot:default="{ active }">
                    <v-btn icon class="mover">
                      <v-icon>mdi-format-line-spacing</v-icon>
                    </v-btn>
                    <div
                      style="width:100%"
                      class="d-flex justify-space-between"
                      @click="checkItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="unselect">{{
                          item.name
                        }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          @change="checkItem(item)"
                          v-model="item.check"
                          :input-value="active"
                          :disabled="is_readonly"
                        ></v-checkbox>
                      </v-list-item-action>
                    </div>
                  </template>
                </v-list-item>
              </div>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create() : save()"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import draggable from "vuedraggable";
import { modify_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "PASSWORDEditor",
  props: ["source_id", "is_readonly", "type", "allDatas", "psdSources"],
  data() {
    return {
      valid: true,
      name: "",
      external_id: "password",
      sourcesData: [],
      checkSources: [],
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      }
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    checkItem(item) {
      if (this.is_readonly) {
        return;
      } else {
        item.check = item.check ? false : true;
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        let filterPsdSources = this.checkSources.filter(item => item.check);
        this.psdSourcesIds = filterPsdSources.map(item => item.id);
        let payload = {
          new_name: this.name,
          new_options: {
            merge_password_source: this.psdSourcesIds
          }
        };

        const promise = modify_request(this.source_id, payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "更新成功";
          })
          .catch(({ code, message }) => {
            throw `企业身份源修改失败：${this.$t(
              "api." + code
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    fetchData() {
      let { name, external_id, options } = this.allDatas;
      this.name = name;
      if (options && options.merge_password_source) {
        let { merge_password_source } = options;
        if (merge_password_source.length > 0) {
          let checkSources = [];
          merge_password_source.forEach(itm => {
            this.psdSources.forEach(item => {
              if (itm === item.id) {
                item.check = true;
                checkSources.push(item);
              }
            });
          });

          let otherSources = this.psdSources.filter(item => !item.check);

          this.checkSources = checkSources.concat(otherSources);
        } else {
          this.checkSources = this.psdSources;
        }
      }

      this.external_id = external_id;
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          // this.reset();
          this.external_id = "sms";
        }
      }
    }
  },
  watch: {
    allDatas() {
      this.fetchData();
    }
  },
  components: {
    draggable
  }
};
</script>
